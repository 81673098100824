<template>
	<section ref="sectionRef"
			 class="flex-module container-fluid flexible-herotext">
		<h1 ref="titleRef"
			class="hero-title">
			{{ text }}
		</h1>
	</section>
</template>

<script setup>
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { SplitText } from 'gsap/SplitText'
import { useMetaStore } from '~/stores/metaStore'
if (process.client) gsap.registerPlugin(ScrollTrigger, SplitText)

const props = defineProps({
	data: {
		type: [Object, String],
		required: true
	},
	delay: {
		type: [Number, String],
		default: null
	}
})

const metaStore = useMetaStore()
const sectionRef = ref(null)
const titleRef = ref(null)
const splitlines = reactive({})
const revealDelay = props.delay ? Number(props.delay) : metaStore.transitionDuration ? metaStore.getDelay : 500
const triggers = {}
let $q


const text = computed(() => {
	return props.data.text || props.data
})

function animRevealTitle() {
	if (Object.prototype.hasOwnProperty.call(splitlines, 'innerSplit')) {
		revertText()
	}

	document.fonts.ready.then(() => {
		splitlines.innerSplit = new SplitText(titleRef.value, {
			type: 'lines',
			linesClass: 'split-child'
		})

		splitlines.outerSplit = new SplitText(titleRef.value, {
			type: 'lines',
			linesClass: 'split-parent'
		})

		// gsap.set($q('.split-parent'), {
		// 	overflow: 'hidden',
		// 	position: 'relative',
		// })
		// gsap.set($q('.split-child'), {
		// 	yPercent: 100,
		// })

		gsap.set($q('.hero-title'), {
			autoAlpha: 1
		})

		delay(revealDelay).then(() => {
			triggers.revealTitle = ScrollTrigger.create({
				id: 'animRevealTitle',
				trigger: sectionRef.value,
				start: 'top bottom',
				onEnter: () => {
					gsap.to(splitlines.innerSplit.lines, {
						y: 0,
						duration: 1.5,
						stagger: 0.2,
						ease: 'expo.out',
						onComplete: () => {
							// console.log('animRevealTitle complete')
							// revertText()
						}
					})
				}
			})
		})
	})
}

function revertText() {
	splitlines.outerSplit.revert()
	splitlines.innerSplit.revert()
}

function initAnims() {
	$q = gsap.utils.selector(sectionRef.value)
	animRevealTitle()
}

onMounted(() => {
	useAnims(initAnims, triggers)
})
</script>

<style lang="scss" scoped>
:deep(.hero-title) {
	@include textH4;
	line-height: 1.3;
	visibility: hidden;

	.split-parent {
		overflow: hidden;
		position: relative;

		.split-child {
			transform: translate(0px, 100%);
		}
	}
}
</style>